import React from 'react';
import '../styles/AboutMe.css'; // Import the styles for the AboutMe component

const AboutMe = () => {
    return (
        <div className="delimiter">
        <div className="section" id="about-me">
            <h2 className="main-title">About Me</h2> {/* Apply main-title class */}
            <div className="about-me-container">
                <div className="about-text">
                    <p>
                        I'm not just all about tech! I find fulfillment in a range of interests. Hiking has become a cherished pursuit. Mt. Katahdin in Maine was my favorite hike so far. I used to be a runner, but switched to bodybuilding and now I spend hours in the gym every day. I also love karaoke! When traveling to new cities I always look up places hosting karaoke that night. While I am based in Philadelphia, the flexibility of remote work has ignited a vision of owning a cabin in the woods, complete with a personal home gym. It will be my sanctuary for relaxation amidst the beauty of nature. I take pleasure in exploring cities on foot, discovering their hidden gems and finding inspiration in unexpected places. Alongside these pursuits, you'll often find me engrossed in literature and staying abreast of the latest tech trends, ensuring I remain on the cutting edge of innovation. Collaboration is my forte, and I eagerly anticipate the opportunity to engage with diverse teams on intricate challenges. I'm poised to bring this dedication to your projects. Let's connect and explore the potential of creating something extraordinary together!
                    </p>
                </div>
                <div className="about-image">
                    <img src="../../images/aboutme.jpg" alt="About Me Image" />
                </div>
            </div>
        </div>
        </div>
    );
};

export default AboutMe;
