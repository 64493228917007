import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import config from '../config/config'; // Import the config object

const VerifyEmail = () => {
  const { token, email } = useParams();
  const [verificationStatus, setVerificationStatus] = useState('Verifying...');
  const { BASE_URL, SOCKET } = require('../config/config');

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        // Make a request to your Flask backend to verify the email
        const response = await axios.post(`${BASE_URL}/api/verify-email`, { token, email });
        // If the request is successful, set the verification status
        setVerificationStatus(response.data.message);
      } catch (error) {
        console.error('Error verifying email:', error);
        setVerificationStatus('An error occurred while verifying your email.');
      }
    };

    verifyEmail();
  }, [token, email]);

  return (
    <div>
      <h1>Email Verification</h1>
      <p>{verificationStatus}</p>
    </div>
  );
};

export default VerifyEmail;
