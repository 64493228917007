import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Navbar from './Navbar';
import Footer from './Footer';
import '../styles/Transcription.css'; // Import CSS file for Summary styling
import config from '../config/config'; // Import the config object
import { useCookies } from 'react-cookie'; // Import useCookies from react-cookie
import TranscriptionRecorder from './TranscriptionRecorder';
import TranscriptionUpload from './TranscriptionUpload';
import TranscriptionSamples from './TranscriptionSamples';

const AudioTranscription = () => {
  const [audioFile, setAudioFile] = useState(null);
  const [transcription, setTranscription] = useState('');
  const [isRecording, setIsRecording] = useState(false); // Define isRecording state variable
  const [audioURL, setAudioURL] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Local state for authentication status
  const navigate = useNavigate();
  const audioRecorder = useRef(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioBlob, setAudioBlob] = useState(null);
  const [fileSizeError, setFileSizeError] = useState(false); // State for file size error message
  const [numTranscriptions, setNumTranscriptions] = useState(null);
  const [cookies] = useCookies(['user_email']); // Use the 'user_email' cookie
  const { BASE_URL, SOCKET } = require('../config/config');
  const [Recording, setRecording] = useState('');
  const [isLoading, setIsLoading] = useState(false); // State to indicate loading state
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

useEffect(() => {
  // Define a function to fetch data from the backend
  const fetchNumTranscriptions = async () => {
    try {
      // Include the user's email in the API request
      const response = await axios.post(`${BASE_URL}/api/get_num_transcriptions`, { email: cookies.email });
      // Extract the value of numTranscriptions from the response data
      const { num_transcriptions } = response.data;
      setNumTranscriptions(num_transcriptions);
    } catch (error) {
      console.error('Error fetching num transcriptions:', error);
    }
  };

  // Call the fetchNumTranscriptions function when the component mounts
  fetchNumTranscriptions();
}, [cookies.user_email, transcription, isLoading]); // Trigger the effect when the user_email cookie or numTranscriptions changes

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    // Check if the file size exceeds the limit (25MB)
    if (file && file.size > 25 * 1024 * 1024) {
      setFileSizeError(true); // Show error message
    } else {
      setAudioFile(file);
      setFileSizeError(false); // Hide error message
    }
  };
  // Function to check if user is authenticated
  const checkAuthentication = () => {
    const sessionToken = document.cookie.includes('session_token');
    setIsAuthenticated(sessionToken);
  };

  // Call the checkAuthentication function when the component mounts
  useEffect(() => {
    checkAuthentication();
  }, []);

  const handleTranscribe = async () => {
    if (!audioFile || fileSizeError) {
      alert('Please select a valid audio file.');
      return;
    }
    setIsLoading(true); // Set loading status to true when API call starts

    const formData = new FormData();
    formData.append('audio', audioFile);
    formData.append('email', cookies.email); // Include email as form data

    try {
      const response = await axios.post(`${BASE_URL}/api/transcribe`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setTranscription(response.data.transcription);
    } catch (error) {
      console.error('Error transcribing audio:', error);
    } finally {
      setIsLoading(false); // Set loading status to false when API call completes
      setActiveTab(3);
    }
  };
const transcribeAudio = async () => {
  if (!audioBlob) {
    console.error('No audio recording available.');
    return;
  }
  if (fileSizeError) {
      alert('Recoring too large.');
      return;
  }
  setIsLoading(true); // Set loading status to true when API call starts
  const formData = new FormData();
  formData.append('audio', audioBlob);
  formData.append('email', cookies.email); // Include email as form data

  try {
    const response = await axios.post(`${BASE_URL}/api/transcribe`, formData);
    setTranscription(response.data.transcription);
  } catch (error) {
    console.error('Error transcribing audio:', error);
  } finally {
      setIsLoading(false); // Set loading status to false when API call completes
      setActiveTab(3);
    }
  };

  const handleLoginClick = () => {
    // Redirect to login page
    navigate('/login');
  };

  const handleSignupClick = () => {
    // Redirect to signup page
    navigate('/signup');
  };
  const handleToggleRecording = () => {
    if (!isRecording) {
      // Start recording
      startRecording();
    } else {
      // Stop recording
      stopRecording();
    }
    setIsRecording(prev => !prev); // Toggle isRecording state
  };
const startRecording = () => {
  navigator.mediaDevices.getUserMedia({ audio: true })
    .then(stream => {
      const recorder = new MediaRecorder(stream);
      setMediaRecorder(recorder);
      const chunks = [];

      recorder.ondataavailable = (e) => {
        chunks.push(e.data);
      };

      recorder.onstop = () => {
        const blob = new Blob(chunks, { type: 'audio/wav' }); // Set MIME type to audio/wav for .wav file
        setAudioBlob(blob);
        const url = URL.createObjectURL(blob);
        setAudioURL(url);
      };

      recorder.start();
      setIsRecording(true);
    })
    .catch(error => {
      console.error('Error accessing microphone:', error);
    });
};

  const stopRecording = () => {
    if (mediaRecorder && isRecording) {
      mediaRecorder.stop();
      setIsRecording(false);
    }
  };

  const handleRecordTranscribe = async () => {
    // Logic to transcribe recorded audio
  };

  const handleSamplePreview = (sampleRecording, sampleTranscription) => {
    setRecording(sampleRecording);
    setTranscription(sampleTranscription);
    setActiveTab(3);
  };
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 900);
    };

    window.addEventListener('resize', handleResize);

    // Call handleResize on initial load
    handleResize();

    // Clean up event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  useEffect(() => {
    checkAuthentication();
  }, []);
  return (
<div className="transcription-wrapper">
  <div className="transcription-header-section">
    <div className="transcription-content">
      <h1>Audio Transcription</h1>
      <div className="page-description">
        <p>This API is built to transcribe audio speech into text. Due to costs of hosting this demo, each user is limited to 5 uses. You can, of course, contact me for any questions, a live demo, or any other feedback!</p>
      </div>
      {!isAuthenticated && (
        <div className="login-message">
          <p>To access full demo functionality, please login.</p>
          <div className="login-buttons">
            <button onClick={handleLoginClick} className="login-button2">Login</button>
            <span> or </span>
            <button onClick={handleSignupClick} className="signup-button">Signup</button>
          </div>
        </div>
      )}
    </div>
          {isSmallScreen && (
            <div className="tab-buttons">
              <button className={activeTab === 0 ? "active" : ""} onClick={() => handleTabClick(0)}>Samples</button>
              <button className={activeTab === 1 ? "active" : ""} onClick={() => handleTabClick(1)}>Upload</button>
              <button className={activeTab === 2 ? "active" : ""} onClick={() => handleTabClick(2)}>Record</button>
              <button className={activeTab === 3 ? "active" : ""} onClick={() => handleTabClick(3)}>Transcript</button>
            </div>
          )}
            {isSmallScreen ? (
              <>
                <div className="summary-tabs">
                {activeTab === 0 && <TranscriptionSamples handleSamplePreview={handleSamplePreview} isLoading={isLoading} />}
                {activeTab === 1 && <TranscriptionUpload handleFileChange={handleFileChange} handleTranscribe={handleTranscribe} isAuthenticated={isAuthenticated} isLoading={isLoading} numTranscriptions={numTranscriptions} />}
                {activeTab === 2 && <TranscriptionRecorder startRecording={startRecording} stopRecording={stopRecording} isRecording={isRecording} audioURL={audioURL} transcribeAudio={transcribeAudio} isAuthenticated={isAuthenticated} isLoading={isLoading} numTranscriptions={numTranscriptions} />}
                {activeTab === 3 && (
                  <div className="transcription">
                      <div>
                        {isAuthenticated && numTranscriptions !== null && (
                          <p>Transcriptions remaining: {numTranscriptions}</p>
                        )}
                      </div>
                      <h2>Transcription</h2>
                      <textarea
                        className="transcription-text"
                        value={transcription}
                        readOnly
                      ></textarea>
                    </div>)}
                </div>
              </>
            ) : (
              <>
                <div className="side-by-side-container-transcription">
                  <div className="samples-transcription">
                    <TranscriptionSamples handleSamplePreview={handleSamplePreview} isLoading={isLoading} />
                  </div>
                  <div className="transcription-elements">
                    <div className="transcription-options">
                      <TranscriptionUpload handleFileChange={handleFileChange} handleTranscribe={handleTranscribe} isAuthenticated={isAuthenticated} isLoading={isLoading} numTranscriptions={numTranscriptions} />
                      <div className="voice-recorder">
                        <TranscriptionRecorder startRecording={startRecording} stopRecording={stopRecording} isRecording={isRecording} audioURL={audioURL} transcribeAudio={transcribeAudio} isAuthenticated={isAuthenticated} isLoading={isLoading} numTranscriptions={numTranscriptions} />
                      </div>
                    </div>
                    <div className="transcription">
                      <div>
                        {isAuthenticated && numTranscriptions !== null && (
                          <p>Transcriptions remaining: {numTranscriptions}</p>
                        )}
                      </div>
                      <h2>Transcription</h2>
                      <textarea
                        className="transcription-text"
                        value={transcription}
                        readOnly
                      ></textarea>
                    </div>
                  </div>
                </div>
              </>
            )}
  </div>
</div>

  );
};

export default AudioTranscription;







