import React, { useState } from 'react';
import '../styles/Resume.css'; // Import the styles for the Resume component

const Resume = () => {
    const [isModalOpen, setModalOpen] = useState(false);

    // Function to toggle the modal
    const toggleModal = () => {
        setModalOpen(!isModalOpen);
    };

    return (
        <div className="delimiter">
            <div className="section" id="resume">
                <h2 className="main-title">Resume</h2> {/* Apply main-title class */}
                <div className="resume" id="pdf-images" style={{ textAlign: 'center' }}>
                    <img
                        src="../../images/Michael-DeMaria-Resume.png"
                        alt="Resume Image"
                        className="resume-image"
                        onClick={toggleModal}
                    />
                    <div className="btn-container">
                        <a href="/static/files/Michael-DeMaria-Resume.pdf" download="Michael-DeMaria-Resume.pdf" className="btn">Download Resume</a>
                    </div>
                </div>
                {isModalOpen && (
                    <div className="modal">
                        <div className="modal-content">
                            <span className="close" onClick={toggleModal}>&times;</span>
                            <img
                                src="../../images/Michael-DeMaria-Resume.png"
                                alt="Resume Image"
                                className="resume-image-full"
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Resume;
