// config.js

const config = {
  development: {
    BASE_URL: 'http://localhost:5000',
    SOCKET: ''
  },
  production: {
    BASE_URL: '', // Add your production base URL here
    SOCKET: '/socket.io'
  },
};

module.exports = config[process.env.NODE_ENV || 'development'];
