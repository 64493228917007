
// Header.js

import React from 'react';
import '../styles/Header.css'; // Import CSS file for styling

const Header = () => {
    return (
        <div className="delimiter">
        <header>
            <div className="header-container">
                {/* Main Title */}
                <h1 className="main-title">Michael DeMaria</h1>
                
                {/* Profile Picture */}
                <div className="profile-picture-container">
                    <img src="../../images/profile-pic.jpg" alt="Your Picture" class="profile-picture"/>
                </div>

                {/* Cover Letter */}
                <div className="cover-letter-container">
                    <div className="cover-letter" id="cover-letter">

            <p>As a versatile professional with expertise in data science, engineering, and full-stack development, I am driven by a passion for leveraging technology to enhance efficiency and drive innovation. With a keen entrepreneurial spirit, I specialize in developing end-to-end systems that deliver tangible results.</p>
            <p>My focus on software automation stems from a commitment to optimizing operations and resource utilization, leading to significant productivity gains. I excel in diverse environments and thrive on the challenge of applying my skills across various industries, including healthcare, finance, and e-commerce.</p>
            <p>I am enthusiastic about collaborating on cutting-edge projects and contributing to the success of forward-thinking organizations. If you seek a versatile professional with a proven track record of innovation and excellence, I am eager to connect and explore opportunities for collaboration.</p>
            <p>I look forward to the possibility of working together.</p>
                </div>
                </div>
            </div>
        </header>
        </div>
    );
}

export default Header;