import React from 'react';
import Navbar from './Navbar'; // Import the Navbar component
import Footer from './Footer'; // Import the Footer component
import '../styles/ThankYouPage.css'; // Import the CSS file for the Thank You page styles

function ThankYouPage() {
  return (
      <div className="thank-you-page-content">
        <h1 className="main-header">Thank you for contacting me!</h1>
        <p className="regular-text">Your message has been sent successfully.</p>
      </div>
  );
}

export default ThankYouPage;
