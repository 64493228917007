import React, { useState, useRef } from 'react';

const AudioRecorder = ({ startRecording, stopRecording, isRecording, audioURL, transcribeAudio, isAuthenticated, isLoading, numTranscriptions }) => {
  return (
    <div className="voice-recorder">
      <h2>Record Audio</h2>
      <div>
        <button
          className={`voice-recorder-button ${isRecording ? 'recording' : ''}`}
          onClick={!isRecording ? startRecording : stopRecording}
        >
          {isRecording ? 'Stop Recording' : 'Start Recording'}
        </button>

        {isRecording && (
          <div style={{ color: 'red', marginTop: '10px' }}>
            Recording...
          </div>
        )}
        {audioURL && (
          <div>
            <audio controls src={audioURL}></audio>
            <div>
              <button onClick={transcribeAudio} disabled={!isAuthenticated || isLoading || numTranscriptions === 0}>Transcribe</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AudioRecorder;
