import React from 'react';
import '../styles/EmailVerification.css';

const EmailVerification = () => {
  return (
    <div className="email-verification-section">
      <h2>Email Verification</h2>
      <p>
        Thank you for signing up! An email with a verification code has been sent to your email address.
        Please check your inbox and follow the instructions to complete the verification process.
      </p>
    </div>
  );
};

export default EmailVerification;
