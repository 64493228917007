import React, { useState, useEffect, useRef } from 'react';
import Modal from './Modal'; // Import your modal component
import '../styles/TechGallery.css'; // Import your CSS file for styling

const TechGallery = () => {
  // Sample images and descriptions
  const images = [
    { src: 'images/gallery/S1.png', description: 'Stress testing an API. Based on user requirements, this particular API needed to be able to handle 2 calls per second. It well exceeded such with fairly cheap compute.' },
    { src: 'images/gallery/S2.png', description: 'In-depth view of API stress testing. Clearly the hosting compute cluster could not handle the volumne of requests.' },
    { src: 'images/gallery/S3.png', description: 'DataBricks workflow. This one runs once daily. We can see it succeeded every run in the visible date range as well as how long it took to run.' },
    { src: 'images/gallery/S4.png', description: 'DataBricks workflow tasks. A fairly simple diagram of python scripts running where some tasks are dependant on the completion of previous ones.' },
    { src: 'images/gallery/S5.png', description: 'Power BI dashboard showing the success rate of an audio transcription service.' },
    { src: 'images/gallery/S6.png', description: 'Power BI dashboard that tracks various metrics such as data drift. As we can see data drift is quite rampant and the model needs to be retrained.' },
    { src: 'images/gallery/S7.png', description: 'Power BI dashboard showing the use of a custom built chatbot software.' },
    { src: 'images/gallery/S8.png', description: 'Quick python analytics on an audio transcription service that shows how many files were completed, failed due to various reasons, or still processing. Oftentimes a fast and simple python chart provides insight for development or debugging.' },
    { src: 'images/gallery/S9.png', description: 'Azure Data Factory. Small example of a pipeline pushing data generated from a model and pushing it to a Cosmos database.' },
    { src: 'images/gallery/S10.png', description: 'DataBricks workflow list. DataBricks makes it easy to schedule multiple jobs that can run on any schedule or trigger you desire.' },
  ];

  // State to manage modal visibility and selected image
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  // Ref for the grid container
  const gridRef = useRef(null);

  // Function to open modal and set selected image
  const handleImageClick = (index) => {
    setSelectedImage(index);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedImage(null);
  };

  useEffect(() => {
    // Calculate grid placement for each image
    const calculateGridPlacement = () => {
      const grid = gridRef.current;
      const gridGap = parseInt(window.getComputedStyle(grid).gap);
      const gridWidth = grid.offsetWidth;

      let totalCols = 0;
      let currentRowHeight = 0;
      let currentRowTop = 0;

      images.forEach((image, index) => {
        const aspectRatio = image.width / image.height;
        const width = Math.min(gridWidth, image.width);
        const height = width / (aspectRatio);

        if (totalCols + width > gridWidth) {
          // Move to next row
          totalCols = 0;
          currentRowTop += currentRowHeight + gridGap;
          currentRowHeight = 0;
        }

        const colStart = totalCols;
        const colEnd = totalCols + width;
        const rowStart = currentRowTop;
        const rowEnd = currentRowTop + height;

        const gridItem = grid.children[index];
        gridItem.style.gridArea = `${rowStart} / ${colStart} / ${rowEnd} / ${colEnd}`;

        totalCols += width + gridGap;
        currentRowHeight = Math.max(currentRowHeight, height);
      });
    };

    calculateGridPlacement();
    window.addEventListener('resize', calculateGridPlacement);

    return () => {
      window.removeEventListener('resize', calculateGridPlacement);
    };
  }, [images]);

  return (
    <div className="tech-gallery" ref={gridRef}>
      {images.map((image, index) => (
        <div key={index} className="gallery-item" onClick={() => handleImageClick(index)}>
          <img src={image.src} alt={image.description} />
          <p>{image.description}</p>
        </div>
      ))}
      {modalOpen && (
        <Modal image={images[selectedImage]} onClose={closeModal} />
      )}
    </div>
  );
};

export default TechGallery;