import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie'; // Import useCookies from react-cookie
import '../styles/Navbar.css'; // Import CSS file for Navbar styling

function Navbar() {
  const location = useLocation(); // Get the current location
  const hideLinks = ["/thank-you", "/projects", "/summary", "/login", "/signup", "/computer-vision" , "/transcription", "/emailverification"
    , "/tech-gallery"].includes(location.pathname);
  const validPaths = ["/summary", "/projects", "/transcription"]; // Add other paths as needed
  const showAuthButtons = validPaths.includes(location.pathname);
  const [cookies, setCookie, removeCookie] = useCookies(['session_token']); // Use useCookies hook
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null); // Reference to the menu element
  // Check if the user is authenticated
  const isAuthenticated = !!cookies.session_token;

  const handleLogoff = () => {
    // Remove the session_token cookie
    removeCookie('session_token');
    // Redirect to the login page or perform any other desired action
    window.location.reload();
  };
const toggleMenu = () => {
  setMenuOpen(!menuOpen); // Toggle the menuOpen state
};

  // Close the menu when clicking outside of it
useEffect(() => {
  function handleClickOutside(event) {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      !isAboveMenu(event, menuRef.current) &&
      !event.target.classList.contains("menu-toggle")
    ) {
      setMenuOpen(false);
    }
  }

  function isAboveMenu(event, menu) {
    const rect = menu.getBoundingClientRect();
    return event.clientY < rect.top;
  }

  document.addEventListener("mousedown", handleClickOutside);
  return () => {
    document.removeEventListener("mousedown", handleClickOutside);
  };
}, [menuRef]);


  return (
<nav className="navbar">
  <div className="nav-left">
    <a className="nav-link" href="https://mikedemaria.com">mikedemaria.com</a>
  </div>
  <div className="nav-right">
    {/* Conditionally render the hamburger icon if hideLinks is false */}
    {!hideLinks && (
<button className="menu-toggle" onClick={toggleMenu}>
        <div className={`menu-bar ${menuOpen ? 'open' : ''}`}></div>
        <div className={`menu-bar ${menuOpen ? 'open' : ''}`}></div>
        <div className={`menu-bar ${menuOpen ? 'open' : ''}`}></div>
      </button>
    )}
<div ref={menuRef} className={`nav-links ${menuOpen ? 'open' : ''}`}>
  {/* Conditionally render the links based on the current location and menu state */}
  {!hideLinks ? (
    <>
      <a onClick={toggleMenu} className="nav-link" href="#resume">Resume</a>
      <a onClick={toggleMenu} className="nav-link" href="#projects">Projects</a>
      <a onClick={toggleMenu} className="nav-link" href="#about-me">About Me</a>
      <a href="https://www.linkedin.com/in/michael-a-demaria" target="_blank" className="linkedin-link" tabIndex="-1">
        <img src="../../images/linkedin.png" alt="LinkedIn Logo" className="linkedin-logo" />
      </a>
    </>
  ) : (
    // Render nothing when links are hidden
    null
  )}
  {/* Always render the "Back to Projects" link except on the /projects page */}
  {location.pathname !== "/" && location.pathname !== "/projects" && (
    <Link to="/projects" className="nav-link" onClick={() => window.location.href = '/projects'}>Back to Projects</Link>
  )}
</div>

    {/* Conditionally render login/signup buttons */}
    {showAuthButtons && !isAuthenticated ? (
      <div className="auth-buttons">
        <Link to="/login" className="auth-button">Login</Link>
        <Link to="/signup" className="auth-button">Signup</Link>
      </div>
    ) : null}
    <div className="auth-buttons">
      {/* Conditionally render logoff button when authenticated on the summary page */}
      {showAuthButtons && isAuthenticated && (
        <button onClick={handleLogoff} className="logoff-button">Logoff</button>
      )}
    </div>
  </div>
</nav>

  );
}

export default Navbar;
