import React from 'react';
import '../styles/TranscriptionSamples.css'; // Import CSS file for Summary styling

const TranscriptionSamples = ({ handleSamplePreview, isLoading }) => {

  const samples = [
    { title: 'Sample Speech 1', recording: require('../audio/harvard.wav'), transcription: `The stale smell of old beer lingers. It takes heat to bring out the odor. A cold dip restores health and zest. A salt pickle tastes fine with ham. Tacos al pastor are my favorite. A zestful food is the hot cross bun.` },
    { title: 'Sample Speech 2', recording: require('../audio/f.wav'), transcription: `Perhaps this is what gives the aborigini his honor of dignity. Tribulent tides rose as much as 50 feet. In the choreographer must arbitrate. He did not, however, settle back into acquiescence with things as they were. He finds it in utter misery and desolation. Ironically enough, in this instance such personal virtues were a luxury. Two other cases also were under advisement. Say he is a horse thief, runs an old eddich. He may have a point in urging that decant themes be given fewer prizes. The straight line would symbolize its uniqueness, the circle its universality. Fill small hole in bowl with clay. It has multiple implications and possible headaches for your marketing program. No manufacturer has taken the initiative and pointed out the cost involved. Cricket, overlapping, twisted or widely spaced teeth. Do you always navigate like this?` },
    { title: 'Sample Speech 3', recording: require('../audio/f2.wav'), transcription: `They used an aggressive policeman to flag saltless motorists. The overweight charming could slip poison into anyone's tea. Shaving cream is a popular item on Halloween. I'd ride the subway but I haven't enough change. Eating spinach nightly increases strength miraculously. Those who teach values first abolish cheating. Critical equipment needs proper maintenance. I'd ride the subway but I haven't enough change.` }
  ];

  return (
  <div className="sample-section-transcription">
    <h2>Samples</h2>
    {samples.map((sample, index) => (
      <div className="sample-transcription" key={index}>
        <p>{sample.title}</p>
        {/* Embed the audio file */}
        {sample.recording ? (
          <audio controls className="audio-player">
            <source src={sample.recording} type="audio/wav" />
            Your browser does not support the audio element.
          </audio>
        ) : (
          <p>No audio recording available</p>
        )}
        <button 
          className="preview-button-transcription" 
          onClick={() => handleSamplePreview(sample.recording, sample.transcription)} 
          disabled={!sample.recording || isLoading} // Disable button when loading or if no recording
        >
          Preview
        </button>
      </div>
    ))}
  </div>
  );
};

export default TranscriptionSamples;