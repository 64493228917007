import React from 'react';

const FileUploader = ({ handleFileChange, handleTranscribe, isAuthenticated, isLoading, numTranscriptions }) => {
  return (
    <div className="file-upload">
      <h2>Upload File</h2>
      <div className="file-upload-button">
        <input type="file" accept="audio/*" onChange={handleFileChange} maxLength={25 * 1024 * 1024}/>
      </div>
      <button onClick={handleTranscribe} disabled={!isAuthenticated || isLoading || numTranscriptions === 0}>Transcribe</button>
    </div>
  );
};

export default FileUploader;
