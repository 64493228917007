// SummarySummaryTextBox.js
import React from 'react';
import '../styles/SummarySummaryTextBox.css'; // Import CSS file for Summary styling

const SummarySummaryTextBox = ({ summary }) => {
  return (
    <div>
      <h2>Summary</h2>
      <textarea
        className="summary-textbox"
        value={summary}
        readOnly
      />
    </div>
  );
};

export default SummarySummaryTextBox;
