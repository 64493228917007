import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import EmailVerification from './EmailVerification';
import '../styles/Signup.css';
import Navbar from './Navbar';
import Footer from './Footer';
import config from '../config/config'; // Import the config object

const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate(); // Get the navigate function from react-router-dom
  const [loading, setLoading] = useState(false);
  const [signupSuccess, setSignupSuccess] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const { BASE_URL, SOCKET } = require('../config/config');

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === 'email') {
      setEmail(value);
    } else if (name === 'password') {
      setPassword(value);
    }
  };
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Local state for authentication status

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (password === confirmPassword) {
    console.log('Form submitted');

    try {
      setLoading(true); // Set loading state to true

      const response = await axios.post(`${BASE_URL}/api/signup`, {
        email,
        password,
      });

      console.log('Signup request sent');
      console.log('Response:', response);
      console.log('Signup successful:', response.data);

      if (response.status === 200) {
        setSignupSuccess(true); // Set signup success state to true
        // Redirect to the email verification page
        window.location.href = '/emailverification';
        // setLoading(false); // Set loading state back to false
      }

    } catch (error) {
      console.error('Signup failed:', error.message);
    }    } else {
      setPasswordMatchError(true);
    }
  };

  const handleLoginClick = () => {
    navigate('/login');
  };

return (
<div className="signup-container">
  <h2>Sign Up</h2>
  {loading ? (
    <div className="loading-spinner"></div> // Show loading spinner
  ) : (
    <form onSubmit={handleFormSubmit}>
      <div className="input-container">
        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </div>
      <div className="input-container">
        <label htmlFor="password">Password:</label>
        <input
          type="password"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
      </div>
      <div className="input-container">
        <label htmlFor="confirmPassword">Confirm Password:</label>
        <input
          type="password"
          id="confirmPassword"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
      </div>
      {passwordMatchError && <p>Passwords do not match</p>}
      <button type="submit" disabled={loading}>Sign Up</button> {/* Disable the button if loading */}
    </form>
  )}
  <p>
    Already have an account?
    <button className="signup-login-button" onClick={handleLoginClick} disabled={loading}>Log in</button> {/* Disable the button if loading */}
  </p>
  <div className="note-message">
    <p>
      Note: This is just so bots do not use this website. No need for a super secure password. It is encrypted, but your account will not be saved long term.
    </p>
  </div>
</div>



  );
};


export default Signup;
