import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import '../styles/Login.css';
import config from '../config/config'; // Import the config object

const Login = () => {
  const [cookies, setCookie] = useCookies(['sessionToken']);
  const navigate = useNavigate(); // Get the navigate function from react-router-dom
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showVerificationButton, setShowVerificationButton] = useState(false);
  const [verificationSent, setVerificationSent] = useState(false);
  const expirationTimestamp = Date.now() + 600000; // 10 minutes
  const [rememberEmail, setRememberEmail] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Local state for authentication status
  const { BASE_URL, SOCKET } = require('../config/config');

  // Load the rememberEmail value from local storage on component mount
  useEffect(() => {
    const storedRememberEmail = localStorage.getItem('rememberEmail');
    const storedEmail = localStorage.getItem('email');

    if (storedRememberEmail) {
      setRememberEmail(JSON.parse(storedRememberEmail));
    }

    if (storedEmail && JSON.parse(storedRememberEmail)) {
      setEmail(JSON.parse(storedEmail));
    }
  }, []);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const response = await axios.post(`${BASE_URL}/api/login`, { email, password })

      const sessionToken = response.data.session_token; // Replace with your actual session token value
      setCookie('session_token', sessionToken, { path: '/', expires: new Date(expirationTimestamp) });
      const cookie_email = response.data.email; // Replace with your actual session token value
      setCookie('email', cookie_email, { path: '/' });

      if (rememberEmail) {
        localStorage.setItem('rememberEmail', JSON.stringify(rememberEmail));
        localStorage.setItem('email', JSON.stringify(email));
      } else {
        localStorage.removeItem('rememberEmail');
        localStorage.removeItem('email');
      }

    navigate('/projects');

    } catch (error) {
      console.error('Login failed:', error.message);
      if (error.response && error.response.status === 401) {
        setErrorMessage('Email/password match incorrect.');
      }
      else if (error.response && error.response.status === 403) {
        setShowVerificationButton(true);
        setErrorMessage('');
      } else {
        console.log(error.message);
        setErrorMessage('Login failed. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleResendVerification = async (e) => {
    e.preventDefault(); // Prevent form submission

  try {
      setLoading(true);
      await axios.post(`${BASE_URL}/api/resend-verification-email`, {email});
      setErrorMessage('');
      setShowVerificationButton(false);
      setVerificationSent(true);
    } catch (error) {
      console.error('Failed to resend verification email:', error.message);
    } finally {
      setLoading(false);
    }
};
  const handleRememberEmail = (e) => {
    setRememberEmail(e.target.checked);
    if (!e.target.checked) {
        localStorage.removeItem('rememberEmail');
    }
    else {
        localStorage.setItem('rememberEmail', JSON.stringify(rememberEmail));

    }
  };

return (
    <div className="login-container">
      <h2 className="login-heading">Log in</h2>
      <form className="login-form" onSubmit={handleSubmit}>
        {!verificationSent && ( // Conditionally render the email and password fields
          <>
            <label className="login-label">
              Email:
              <input
                className="login-input-email"
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </label>
            <label className="login-label">
              Password:
              <input
                className="login-input-password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </label>
            <label>
          Remember Email:
          <input
            type="checkbox"
            checked={rememberEmail}
            onChange={handleRememberEmail}
          />
        </label>
          </>
        )}
        {!verificationSent && ( // Conditionally render the login button
          <button className="login-button" type="submit">
            Log In
          </button>
        )}
        {errorMessage && <p className="login-error">{errorMessage}</p>}
        {showVerificationButton && !verificationSent && (
          <div className="login-verification">
            <p className="login-verification-text">
              Your email is not yet verified. Would you like to send a verification email?
            </p>
            <button className="login-verification-button" onClick={handleResendVerification}>
              Send Verification Email
            </button>
          </div>
        )}
        {verificationSent && ( // Conditionally render the verification success message
          <p className="login-verification-sent">Verification email sent. Please check your email.</p>
        )}
      </form>
      <p className="login-signup">
        Don't have an account? <Link to="/signup">Sign up</Link>
      </p>
    </div>
  );
};
export default Login;
