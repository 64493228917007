import React from 'react';
import '../styles/Modal.css'; // Import your CSS file for styling

const Modal = ({ image, onClose }) => {
  const handleClose = (e) => {
    // Prevent propagation of the click event to the overlay
    e.stopPropagation();
    // Call onClose function to close the modal
    onClose();
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content">
        <p className="description">{image.description}</p>
        <img src={image.src} alt={image.description} onClick={handleClose} />
      </div>
    </div>
  );
};

export default Modal;