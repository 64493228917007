import React from 'react';
import '../styles/Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <p className="footer-text">© 2024 Michael DeMaria</p>
      <img src="/images/phoenixlogo.png" alt="Image" className="footer-image" />
    </footer>
  );
}

export default Footer;
