import React from 'react';
import '../styles/SummarySamples.css'; // Import CSS file for Summary styling


const SummarySamples = ({ handleSamplePreview, isLoading }) => {

  const samples = [
    { title: 'Short Story', longText: `In the heart of the bustling city, there stood a quaint little bookstore named "Whispering Pages." Its shelves were lined with books of every genre imaginable, from classic literature to contemporary thrillers. The owner, Mr. Emerson, was a kindly old man with a passion for storytelling. He had spent his entire life surrounded by books and cherished each one like a dear friend.

One rainy afternoon, a young woman named Alice stumbled upon Whispering Pages while seeking shelter from the storm. She was drawn to the bookstore's cozy atmosphere and the promise of literary treasures waiting to be discovered. As she browsed the shelves, a faded leather-bound book caught her eye. Its title, "The Secret Garden," stirred something deep within her soul.

Little did Alice know, this book would change her life forever. With each turn of the page, she was transported to a magical world where anything was possible. She found solace in the story of Mary Lennox and her journey of self-discovery amidst the beauty of nature.

As days turned into weeks, Alice returned to Whispering Pages time and time again, eager to lose herself in the pages of a new adventure. With each book she read, she felt herself growing stronger, more confident, and more in tune with the world around her. And through it all, Mr. Emerson was there, offering words of wisdom and encouragement.

Eventually, Alice realized that Whispering Pages was more than just a bookstore—it was a sanctuary for the soul. It was a place where dreams took flight, and where the power of imagination knew no bounds. And as she stepped out into the world once more, she carried with her the stories she had read and the memories she had made, knowing that she would always have a home among the Whispering Pages.
`, summary: `In the heart of the city lies "Whispering Pages," a bookstore that becomes a sanctuary for a young woman named Alice. She discovers the transformative power of storytelling through "The Secret Garden" and other books, finding solace, strength, and a sense of belonging within its walls. Guided by the kind owner, Mr. Emerson, Alice's journey at Whispering Pages teaches her the value of imagination and the magic of literature.
` },
    { title: 'Conversation', longText: `Alice: Hey Bob, have you heard about the new restaurant that opened downtown?
Bob: No, I haven't. What's it called?
Alice: It's called "The Golden Spoon." I heard they serve amazing seafood dishes.
Charlie: Sounds interesting. When did it open?
Alice: I think it opened last week. We should check it out sometime.
Bob: Definitely! I've been craving some good seafood lately.
Charlie: Count me in too. Let's make plans to go there this weekend.
Alice: Great! I'll make reservations for Saturday evening.
Bob: Sounds like a plan. I can't wait to try their lobster bisque.
Charlie: And I've heard they have a delicious chocolate lava cake for dessert.
Alice: Perfect! It's settled then. We'll meet at "The Golden Spoon" on Saturday at 7 PM.

Alice: By the way, did you guys see the news about the upcoming concert in the park?
Bob: No, I haven't. Who's performing?
Alice: It's a local band called "The Midnight Riders." They play a mix of rock and blues.
Charlie: That sounds like a fun way to spend a Saturday afternoon. When is it?
Alice: It's next weekend, on Saturday afternoon. Admission is free, and they'll have food trucks and games too.
Bob: That sounds awesome! We should definitely go and support local talent.
Charlie: I'm in! It'll be a great way to enjoy some live music and soak up the sun.
Alice: I'll bring a picnic blanket, and we can relax and enjoy the music together.
Bob: Sounds like a plan. Let's make it a date for next Saturday!

Charlie: Hey guys, have you seen the new movie that just came out?
Alice: No, I haven't had a chance to go to the movies lately. What's it called?
Charlie: It's called "Into the Unknown." It's a sci-fi thriller about a group of astronauts on a dangerous mission to explore a distant planet.
Bob: That sounds intense. Is it worth seeing?
Charlie: Definitely! I saw it last night, and it kept me on the edge of my seat the whole time.
Alice: I'm a sucker for a good sci-fi movie. Let's go see it together this weekend.
Bob: I'm up for it. Saturday evening works for me.
Charlie: Sounds good. I'll book the tickets online and let you guys know the details.
Alice: Awesome! I can't wait to see it. Thanks for the recommendation, Charlie.`, summary: `Alice, Bob, and Charlie make plans to dine at "The Golden Spoon" restaurant on Saturday evening. They also decide to attend a concert in the park next weekend and watch the movie "Into the Unknown" together. Their conversation revolves around enjoying food, music, and movies as friends.
` },
    { title: 'Product Description', longText: `Introducing the latest model in our professional camera lineup: the AlphaX 5000. Designed for professional photographers and enthusiasts alike, the AlphaX 5000 combines cutting-edge technology with unparalleled performance to deliver stunning images and videos in any shooting situation.

Equipped with a 40-megapixel full-frame sensor and advanced image processing algorithms, the AlphaX 5000 captures every detail with exceptional clarity and accuracy. Its lightning-fast autofocus system ensures precise focus even in low-light conditions, while the built-in image stabilization technology minimizes blur for sharp, steady shots.

The AlphaX 5000 features a versatile 24-70mm f/2.8 lens, perfect for capturing a wide range of subjects, from sweeping landscapes to close-up portraits. With its wide aperture and shallow depth of field, this lens produces beautiful bokeh effects, making your subjects stand out against a soft, creamy background.

In addition to its outstanding still image capabilities, the AlphaX 5000 excels in the realm of video production. It supports 4K video recording at 60 frames per second, allowing you to capture stunningly detailed footage with smooth, lifelike motion. The camera's advanced video features, including focus peaking and zebra striping, make it a powerful tool for filmmakers and content creators.

Designed for durability and reliability, the AlphaX 5000 features a rugged magnesium alloy body that is weather-sealed to withstand the elements. Its intuitive interface and customizable controls make it easy to use in any shooting situation, while its long-lasting battery ensures you never miss a shot.

Whether you're a professional photographer looking for the ultimate tool for your craft or an enthusiast seeking to take your photography to the next level, the AlphaX 5000 is the perfect choice. With its exceptional image quality, advanced features, and rugged design, it's ready to accompany you on your photographic journey.
`, summary: `The AlphaX 5000 is a professional-grade camera designed for photographers and enthusiasts. With its 40-megapixel full-frame sensor, lightning-fast autofocus, and 4K video recording capabilities, it delivers exceptional image quality and performance in any shooting situation. The camera's versatile lens, rugged design, and intuitive interface make it the perfect choice for professionals and enthusiasts alike.
` }
  ];

  return (
    <div className="sample-section">
      <h2>Samples</h2>
      {samples.map((sample, index) => (
        <div className="sample" key={index}>
          <p>{sample.title}</p>
          <button 
            className="preview-button" 
            onClick={() => handleSamplePreview(sample.longText, sample.summary)} 
            disabled={isLoading} // Disable button when loading
          >
            Preview
          </button>
        </div>
      ))}
    </div>
  );
};

export default SummarySamples;