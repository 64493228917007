import React, { useEffect, useRef, useState } from 'react';
import io from 'socket.io-client';
import Navbar from './Navbar';
import Footer from './Footer';
import config from '../config/config'; // Import the config object
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import '../styles/ObjectDetection.css'; // Import the CSS file for the Thank You page styles

const WebcamStream = () => {
  const videoRef = useRef(null);
  const originalCanvasRef = useRef(null);
  const processedCanvasRef = useRef(null);
  const captureFrameIntervalRef = useRef(null);
  const thirdImageRef = useRef(null); // Ref for the third image
  const socketRef = useRef();
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [thirdImage, setThirdImage] = useState(null);
  const [effect, setEffect] = useState('box-blur-medium');
  const [activeTab, setActiveTab] = useState(0);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // State to track loading state
  const [isSocketStreaming, setIsSocketStreaming] = useState(true);
  const [isWebcamStreaming, setIsWebcamStreaming] = useState(true);
  const [sessionId, setSessionId] = useState(null); // Store session ID
  const { BASE_URL, SOCKET } = require('../config/config');

  useEffect(() => {
    const handleResize = () => {setIsSmallScreen(window.innerWidth <= 1200);};handleResize();window.addEventListener('resize', handleResize);
    return () => {window.removeEventListener('resize', handleResize);};}, []);

  useEffect(() => {
    // Generate a unique session ID when component mounts
    const newSessionId = generateSessionId();
    setSessionId(newSessionId); // Set session ID
  }, []); // Empty dependency array to run only once on component mount

  useEffect(() => {
    if (sessionId) {
      initializeSocket();
      startWebcam();
    }
  }, [sessionId]); // Run only when sessionId is set

  const generateSessionId = () => {
    // Generate a unique session ID
    return Math.random().toString(36).substring(2, 30);
  };
  const initializeSocket = () => {
    socketRef.current = io(`${BASE_URL}`, { path: `${SOCKET}` });
      socketRef.current.on('processedFrame', (data) => {
    handleProcessedFrame(data);
  });
    return () => socketRef.current.disconnect();
  };
const handleProcessedFrame = (processedData) => {
  const { socketSessionId, socketImageData } = processedData;
  // Check if the sessionId matches the sessionId from React
  if (socketSessionId === sessionId) {
    const processedCanvas = processedCanvasRef.current;
    if (!processedCanvas) {
      console.error('Processed canvas element is not available.');
      return;
    }
    const processedCtx = processedCanvas.getContext('2d');
    if (!processedCtx) {
      console.error('Failed to obtain 2D context for processed canvas.');
      return;
    }
    const processedImg = new Image();
    processedImg.onload = () => {
      // Set canvas dimensions to match image dimensions
      processedCanvas.width = processedImg.width;
      processedCanvas.height = processedImg.height;
      console.log(processedCanvas.width + "   width, height: " + processedCanvas.height);
      processedCtx.clearRect(0, 0, processedCanvas.width, processedCanvas.height);
      processedCtx.drawImage(processedImg, 0, 0, processedCanvas.width, processedCanvas.height);
    };
    processedImg.src = socketImageData;
  }
};

  const captureAndSendFrame = (effect) => {
    try {
      const video = videoRef.current;
      if (!videoRef.current) {console.log("Video element is not available. Aborting capture and send frame.");return;}
      if (video && video.videoWidth && video.videoHeight) {
        const canvas = document.createElement('canvas');
        canvas.width  = video.videoWidth;
        canvas.height = video.videoHeight;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        const imageData = canvas.toDataURL('image/jpeg');
        if (isSocketStreaming) {
        socketRef.current.emit('process-image', { image_data: imageData, ieffect: effect, sessionId: sessionId });
        }
      } else {console.error('Video element is null or its dimensions are not available.');}
    } catch (error) {console.error('An error occurred while capturing and sending frame:', error);}
  };

  const startWebcam = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;

        captureFrameIntervalRef.current = setInterval(() => {
          setEffect(currentEffect => {
            captureAndSendFrame(currentEffect);
            return currentEffect;
          });
        }, 600);
      }
    } catch (err) {
      console.error('Error accessing webcam:', err);
    }
  };

  // Function to handle API call to Flask
  const handleProcessClick = async () => {
    try {
      setIsLoading(true);
      const imageData = await captureFrame();
      const response = await axios.post(`${BASE_URL}/api/object-detection`, { imageData });
      setThirdImage(response.data.imageData); // Update state with image data
    } catch (error) {
      console.error('Error processing image:', error);
    } finally {
      // Reset loading state to false after processing is complete
      setIsLoading(false);
    }
  };
  // Function to capture the current frame from the webcam
  const captureFrame = () => {
    return new Promise((resolve, reject) => {
      try {
        const video = videoRef.current;

        // Check if the video element is available
        if (!video) {
          reject(new Error('Video element is not available.'));
          return;
        }

        // Check if video dimensions are available
        if (video.videoWidth && video.videoHeight) {
          const canvas = document.createElement('canvas');
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
          const imageData = canvas.toDataURL('image/jpeg');
          resolve(imageData);
        } else {
          reject(new Error('Video element dimensions are not available.'));
        }
      } catch (error) {
        reject(error);
      }
    });
  };
  const changeEffect = (newEffect) => {
    setEffect(newEffect);
  };
  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  const toggleWebcamStreaming = () => {
    if (!isWebcamStreaming) {
      // Start webcam and streaming
      setIsWebcamStreaming(true);
      startWebcam();
    } else {
      // Stop webcam and streaming
      setIsWebcamStreaming(false);
      clearInterval(captureFrameIntervalRef.current); // Clear interval when streaming is turned off

      // Stop the webcam stream by stopping all tracks
      const stream = videoRef.current.srcObject;
      if (stream) {
        stream.getTracks().forEach(track => track.stop());
        videoRef.current.srcObject = null; // Clear the srcObject to stop displaying the video
      }
    }
  };



  return (
<div className="full-page-content">
  <div className="margins">
    <div className={`opencv-page ${isSmallScreen && (activeTab !== 0 || activeTab !== 1 || activeTab !== 2) ? 'tab-mode' : ''}`}>
      {(isSmallScreen && (activeTab !== 0 || activeTab !== 1 || activeTab !== 2)) && (
        <div className="tab-buttons">
          <button className={activeTab === 0 ? "active" : ""} onClick={() => handleTabClick(0)}>Original</button>
          <button className={activeTab === 1 ? "active" : ""} onClick={() => handleTabClick(1)}>Convolutions</button>
          <button className={activeTab === 2 ? "active" : ""} onClick={() => handleTabClick(2)}>Object Detection</button>
        </div>
      )}
      <div className="stream-container">
        <div className="stream-section">
          <div className="stream-wrapper">
            <div className="stream" style={{ display: activeTab === 0 || !isSmallScreen ? 'block' : 'none' }}>
              <h3>Original</h3>
              <video ref={videoRef} autoPlay></video>
            </div>
            <div className="stream" style={{ display: activeTab === 1 || !isSmallScreen ? 'block' : 'none' }}>
              <h3>Convolutions</h3>
              <canvas ref={processedCanvasRef} ></canvas>
              <div className="dropdown-container">
                  <select className="effect-dropdown" onChange={(e) => changeEffect(e.target.value)} defaultValue="box-blur-medium">
                    <optgroup label="Colors">
                      <option value="color_inversion">Color Inversion</option>
                      <option value="grayscale">Grayscale</option>
                      <option value="vintage_film">Vintage Film</option>
                      <option value="pop_art">Pop Art</option>
                      <option value="comic_book">Comic Book</option>
                      <option value="sketch">Sketch</option>
                      <option value="watercolor">Watercolor</option>
                    </optgroup>
                    <optgroup label="Filters">
                      <option value="box-blur-large">Box Blur - Large</option>
                      <option value="box-blur-medium">Box Blur - Medium</option>
                      <option value="box-blur-small">Box Blur - Small</option>
                      <option value="edge_detection">Edge Detection</option>
                      <option value="embossing">Embossing</option>
                      <option value="embossing_2">Embossing 2</option>
                      <option value="sharpening">Sharpening</option>
                      <option value="edge_detection_horizontal">Edge Detection (Horizontal)</option>
                      <option value="edge_detection_vertical">Edge Detection (Vertical)</option>
                      <option value="gaussian_blur">Gaussian Blur</option>
                      <option value="laplacian">Laplacian</option>
                      <option value="sobel_x">Sobel (Horizontal)</option>
                      <option value="sobel_y">Sobel (Vertical)</option>
                    </optgroup>
                    <optgroup label="Feature Detection">
                      <option value="FAST">FAST Feature Detection</option>
                      <option value="ORB">ORB Feature Detection</option>
                      <option value="SIFT">SIFT Feature Detection</option>
                      <option value="BRISK">BRISK Feature Detection</option>
                      <option value="KAZE">KAZE Feature Detection</option>
                    </optgroup>
                  </select>
                  <div className="dropdown-arrow"></div>
                </div>
            </div>

                <div className="stream" style={{ display: activeTab === 2 || !isSmallScreen ? 'block' : 'none' }}>
                  <h3>Object Detection</h3>
                  {thirdImage && <img src={thirdImage} alt="Processed Image" />}
                  <div>
                    <button
                      className={`view-project-button ${isLoading ? 'loading' : ''}`} // Add loading class conditionally
                      onClick={handleProcessClick}
                      disabled={isLoading} // Disable button while loading
                    >
                      {isLoading ? 'Loading...' : 'Object Detection'}
                    </button>
                  </div>
                </div>
                          </div>
        </div>

    <div>
<div className="button-container">
  <button onClick={toggleWebcamStreaming} className="toggle-button">{isWebcamStreaming ? 'Turn off Webcam Stream' : 'Turn on Webcam Stream'}</button>
</div>
    </div>
      </div>
    </div>
  </div>
</div>



  );
};

export default WebcamStream;
