import React, { useState } from 'react';
import '../styles/SummaryLongText.css'; // Import CSS file for Summary styling

const SummaryLongText = ({ text, handleChange, handleSubmit, handleLoading, isAuthenticated, numSummaries, isLoading }) => {

  return (
    <div className="SummarizationContentLongBox">
        <h2>Long Text</h2>
        <textarea
          className="summary-text-input"
          value={text}
          onChange={handleChange}
          placeholder="Type your text to summarize here..."
          rows="10"
        ></textarea>
      <div className="submit-button-section">
        <button onClick={handleSubmit} className="submit-button" disabled={!isAuthenticated || isLoading || numSummaries === 0}>
          {isLoading ? 'Submitting...' : 'Submit'}
        </button>
      </div>
      <div>
        {isAuthenticated && numSummaries !== null && (
          <p>Summaries remaining: {numSummaries}</p>
        )}
      </div>
    </div>
  );
};

export default SummaryLongText;
