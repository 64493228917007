import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/Summary.css'; // Import CSS file for Summary styling
import axios from 'axios';
import { useCookies } from 'react-cookie'; // Import useCookies from react-cookie
import config from '../config/config'; // Import the config object
import SummarySamples from './SummarySamples'; // Import the SampleSection component
import SummaryLongText from './SummaryLongText'; // Import the SummaryLongText component
import SummarySummaryTextBox from './SummarySummaryTextBox'; // Import the SummarySummaryTextBox component

const Summary = () => {
  const [numSummaries, setNumSummaries] = useState(null);
  const [cookies] = useCookies(['user_email']); // Use the 'user_email' cookie
  const [summary, setSummary] = useState(''); // State to store the summary text
  const { BASE_URL, SOCKET } = require('../config/config');
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [text, setText] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Local state for authentication status
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false); // State to indicate loading state

  const handleLoading = () => {
    console.log(isLoading)
    setIsLoading(prevLoading => !prevLoading); // Use the previous state to toggle isLoading
  };

  useEffect(() => {
    // Define a function to fetch data from the backend
    const fetchNumSummaries = async () => {
      try {
        // Include the user's email in the API request
        const response = await axios.post(`${BASE_URL}/api/get_num_summaries`, {email: cookies.email });
        // Extract the value of num_summaries from the response data
        const { num_summaries } = response.data;
        setNumSummaries(num_summaries);

      } catch (error) {
        console.error('Error fetching num summaries:', error);
      }
    };

    // Call the fetchNumSummaries function when the component mounts
    fetchNumSummaries();
  }, [cookies.user_email, summary, isLoading]); // Trigger the effect when the user_email cookie changes



  const handleSubmit = async () => {
    try {
      handleLoading();
      // Make the API request to summarize the text
      const response = await axios.post(`${BASE_URL}/api/summary`, { text, email: cookies.email });
      // Extract the summary from the response data
      const { summary } = response.data;
      // Set the summary state
      handleLoading();
      setSummary(summary);
      setActiveTab(2);
    } catch (error) {
      console.error('Error summarizing text:', error);
    }
  };

  const handleChange = (e) => {
    setText(e.target.value);
  };

  const handleLoginClick = () => {
    // Redirect to login page
    navigate('/login');
  };

  const handleSignupClick = () => {
    // Redirect to signup page
    navigate('/signup');
  };

  // Function to check if user is authenticated
  const checkAuthentication = () => {
    const sessionToken = document.cookie.includes('session_token');
    setIsAuthenticated(sessionToken);
  };
  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  useEffect(() => {
    checkAuthentication();
  }, []);


  const handleSamplePreview = (sampleLongText, sampleSummary) => {
    setText(sampleLongText);
    setSummary(sampleSummary);
    setActiveTab(2);
  };
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    // Call handleResize on initial load
    handleResize();

    // Clean up event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="main-container summary-login">
      <div className="summary-wrapper">
        <div className="summary-content">
          <div className="summary-header-section">
            <div className="summary-header-section-contained">
              <h1>Text Summarization</h1>
              <div className="summary-page-description">
                <p>This API is built to summarize large amounts of text. The text can be a conversation between two or more individuals, a story, or any other possibilities. Using the power of LLMs and smart AI text segmentation, it will return a much shorter summarization of the text that encompasses the context with as minimal loss as possible. 
                Due to costs of hosting this demo, each user is limited to 10 uses. You can, of course, contact me for any questions, a live demo, or any other feedback!</p>
              </div>
              {!isAuthenticated && (
                <div className="login-message">
                  <p>To access full demo functionality, please login.</p>
                  <div className="login-buttons">
                    <button onClick={handleLoginClick} className="login-button2">Login</button>
                    <span> or </span>
                    <button onClick={handleSignupClick} className="signup-button">Signup</button>
                  </div>
                </div>
              )}
            </div>
          </div>
          {isSmallScreen && (
            <div className="tab-buttons">
              <button className={activeTab === 0 ? "active" : ""} onClick={() => handleTabClick(0)}>Samples</button>
              <button className={activeTab === 1 ? "active" : ""} onClick={() => handleTabClick(1)}>Long Text</button>
              <button className={activeTab === 2 ? "active" : ""} onClick={() => handleTabClick(2)}>Summary</button>
            </div>
          )}
            {isSmallScreen ? (
              <>
                <div className="summary-tabs">
                {activeTab === 0 && <SummarySamples handleSamplePreview={handleSamplePreview} isLoading={isLoading} />}
                {activeTab === 1 && <SummaryLongText text={text} handleChange={handleChange} handleLoading={handleLoading} handleSubmit={handleSubmit} isAuthenticated={isAuthenticated} numSummaries={numSummaries} isLoading={isLoading} />}
                {activeTab === 2 && <SummarySummaryTextBox summary={summary} />}
                </div>
              </>
            ) : (
              <>
                <div className="side-by-side-container">
                  <SummarySamples handleSamplePreview={handleSamplePreview} isLoading={isLoading} />
                  <div className="SummarizationContent">
                    <SummaryLongText text={text} handleChange={handleChange} handleLoading={handleLoading} handleSubmit={handleSubmit} isAuthenticated={isAuthenticated} numSummaries={numSummaries} isLoading={isLoading} />
                    <SummarySummaryTextBox summary={summary} />
                  </div>
                </div>
              </>
            )}
        </div>
      </div>
    </div>

  );
};

export default Summary;
