import React from 'react';
import '../styles/ProjectsView.css'; // Import CSS file for ProjectsView styling
import { Link } from 'react-router-dom';
import { BASE_URL } from '../config/config.js';

// Sample project data
const projects = [
  {
    title: "Summary API",
    description: "Summarize large volumes of text.",
    link: "/summary",
    buttontext: "View Demo"
  },
  {
    title: "Transcription",
    description: "Transcribe audio file into text.",
    link: "/transcription",
    buttontext: "View Demo"
  },
  {
    title: "Object Detection",
    description: "Detect objects live on camera.",
    link: "/computer-vision",
    buttontext: "View Demo"
  },
  {
    title: "Tech Gallery",
    description: "Images of a wide range of software.",
    link: "/tech-gallery",
    buttontext: "View Gallery"
  }
];

const ProjectsView = () => {
  return (
    <div className="projects-page">
      <div className="projects-page-content">
        <div className="projects-header">
        <h1 className="projects-title">Projects</h1>
        <div className="projects-description">
        <p className="projects-description">Here are a few project demos to show off some of the work I have done. This entire web application is in active development and updates to the UI and functionality will come soon. Some demos require an account to use to prevent bots from using it.</p>
        </div>
        </div>
        {/* Render projects in separate columns */}
        <div className="projects-grid">
          {projects.map((project, index) => (
            <div className="project-column" key={index}>
              <div className="project">
                <h2>{project.title}</h2>
                <p>{project.description}</p>
                <Link to={project.link} style={{ textDecoration: 'none' }}>
                  <button className="view-project-button">{project.buttontext}</button>
                </Link>
              </div>
            </div>
          ))}
          {/* Project 5 column: Coming soon */}
          <div className="project-column">
            <div className="project">
              <h2>Coming Soon</h2>
              <p>More projects coming soon...</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectsView;
