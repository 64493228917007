import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Recaptcha from 'react-google-recaptcha';
import axios from 'axios';
import '../styles/ContactForm.css';
import config from '../config/config'; // Import the config object

const ContactForm = () => {
    const navigate = useNavigate(); // Get the navigate function from react-router-dom
    const { BASE_URL, SOCKET } = require('../config/config');

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const [capVal, setCapVal] = useState(null);

    const handleSubmit = (e) => {
        e.preventDefault();

        let requestData = { ...formData };

        axios.post(`${BASE_URL}/api/send_email`, requestData)
            .then(response => {
                console.log(response.data);
                setFormData({
                    name: '',
                    email: '',
                    message: ''
                });
                navigate('/thank-you');
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };


    return (
        <div className="delimiter">
        <div className="contact-form" style={{ marginBottom: '50px' }}>
            <h2>Contact Me</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="name">Name:</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="message">Message:</label>
                    <textarea
                        id="message"
                        name="message"
                        rows="6"
                        value={formData.message}
                        onChange={handleChange}
                        required
                    ></textarea>
                </div>
        <div className="recaptcha-container">

                    {/* Render reCAPTCHA */}
                    <Recaptcha
                        sitekey="6LddSKkpAAAAAF8ScpXthguMC1zcrX4NpPhAfILe" 
                        onChange={(val) => setCapVal(val)}
                    />
                    </div>

                <button disabled={!capVal}type="submit" className="btn">Submit</button>
            </form>
        </div>
        </div>
    );
};

export default ContactForm;
