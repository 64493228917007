import React from 'react';
import '../styles/ProjectsSection.css'; // Import the styles for the Resume component

function ProjectsSection() {
  return (
    <div className="delimiter">
      <div className="projects-section" id="projects">
        <h2>Projects</h2>
        <div className="content-container">
          <div className="image-container">
            <img src="../../images/fields.png" alt="About Me Image" className="fields-image" />
          </div>
          <div className="text-container">
            <p>
              Explore some of my latest projects and see what we've been working on.
            </p>
            <p>
              Most of my work has been through clients that I cannot share, so I developed this application to show some demos of projects I built.
            </p>
            <div className="btn-container">
              <a href="/projects" className="projects-link">View Projects</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectsSection;
